/* eslint-disable react/no-array-index-key */
import { useState, useEffect, useRef } from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from "primereact/overlaypanel";

import Apexcharts from "components/Apexcharts";
import GraficoHistoricoAdmissaoDesligado from "components/GraficoHistoricoAdmissaoDesligado";
import PDFPreview from "components/PDFPreview";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";
import useQuery from "utils/useQuery";

const IndicadoresRH = () => {
  const query = useQuery();
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const op = useRef(null);

  const { StatusCadastroFuncionariV2, StatusFuncionario, TipoMensal } =
    MaskEnum;

  const [EmpresaId, setEmpresaId] = useState();

  const [ListaFuncionarioEmpresa, setListaFuncionarioEmpresa] = useState([]);
  const [ListaFuncionarioAlocados, setListaFuncionarioAlocados] = useState([]);

  const [CustosPorEmpresa, setCustosPorEmpresa] = useState({});
  const [CustosPorAlocado, setCustosPorAlocado] = useState({});

  const [TurnOver, setTurnOver] = useState({
    turnoverMensal: 0,
    turnoverAnual: 0,
    turnoverTrimestral: 0
  });

  const [DocumentoMensal, setDocumentoMensal] = useState([]);
  const [DocFlatando, setDocFlatando] = useState([]);

  const [NameSelectedImage, setNameSelectedImage] = useState("");
  const [ShowMoldaPdf, setShowMoldaPdf] = useState(false);
  const [PdfFile, setPdfFile] = useState(null);
  const [ControleFerias, setControleFerias] = useState({});

  const [DataGrafico, setDataGrafico] = useState([]);

  const GetDocFlatando = (lista) => {
    try {
      // Lista de tipos obrigatórios
      const listaObg = [1, 2, 3, 4, 5, 6, 7, 14, 15];

      // Extrai os tipos presentes na lista de entrada
      const tiposPresentes = lista.map((item) => item.tipoMensal);

      // Filtra os tipos que estão faltando
      const tiposFaltando = listaObg.filter(
        (tipo) => !tiposPresentes.includes(tipo)
      );

      // Exibe quais tipos estão faltando
      return tiposFaltando;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const HandleDownloadDocumento = async (id, nomeArquivo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Empresa/DocumentoById`,
        params: { id, empreId: EmpresaId },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", nomeArquivo);
      document.body.appendChild(link);
      link.click();
      link.remove();
      return true;
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  const HandleShowDocumento = async (id, nomeArquivo) => {
    try {
      setLoading(true);
      const response = await Requicicao.Get({
        endpoint: `/Empresa/DocumentoById`,
        params: { id, empreId: EmpresaId },
        config: {
          ...auth.GetHeaders(),
          responseType: "blob"
        }
      });

      const blob = new Blob([response], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      setPdfFile(blobUrl);
      setShowMoldaPdf(true);
      setNameSelectedImage(nomeArquivo);

      return true;
    } catch (error) {
      console.error("Erro ao carregar a imagem:", error);
      notify({
        type: "error",
        message: "Erro ao carregar a imagem. Tente novamente mais tarde."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  /**
   * Função para contar as ocorrências
   * @param {array} lista
   * @returns {array}
   */
  const ContarOcorrencias = (lista) =>
    lista.reduce((acc, item) => {
      const statusFinal = item.status || item.statusCadastro; // Usa o statusCadastro se não for null, caso contrário, usa o status

      if (statusFinal != null) {
        // Se já existir, incrementa a contagem, caso contrário, inicializa com 1
        acc[statusFinal] = (acc[statusFinal] || 0) + 1;
      }

      return acc;
    }, {});

  /**
   * Função para converter o objeto de contagem em uma lista de objetos
   * @param {array} contagem
   * @returns {array}
   */
  const ConverterParaListaDeObjetos = (contagem) =>
    Object.keys(contagem).map((key) => ({
      texto: key,
      quantidade: contagem[key]
    }));

  useEffect(() => {
    const GetFuncionarioEmpresa = async () => {
      const resposta = await Requicicao.Get({
        endpoint: `/Funcionarios/Contratados/${EmpresaId}`,
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        const lista = resposta.objetoResposta.map((x) => ({
          statusCadastro:
            StatusCadastroFuncionariV2.find((y) => y.value === x.statusCadastro)
              ?.label || null,
          status:
            StatusFuncionario.find((y) => y.value === x.status)?.label || null
        }));
        setListaFuncionarioEmpresa(
          ConverterParaListaDeObjetos(ContarOcorrencias(lista))
        );
        return true;
      }
      return false;
    };

    const GetFuncionarioAlocados = async () => {
      const resposta = await Requicicao.Get({
        endpoint: `/Funcionarios/Alocados/${EmpresaId}`,
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        const lista = resposta.objetoResposta.map((x) => ({
          statusCadastro:
            StatusCadastroFuncionariV2.find((y) => y.value === x.statusCadastro)
              ?.label || null,
          status:
            StatusFuncionario.find((y) => y.value === x.status)?.label || null
        }));
        setListaFuncionarioAlocados(
          ConverterParaListaDeObjetos(ContarOcorrencias(lista))
        );
        return true;
      }
      return false;
    };

    const GetCustosPorCampoEmpresa = async () => {
      const resposta = await Requicicao.Get({
        endpoint: `/Funcionarios/CustosPorEmpresa`,
        params: { empresaId: EmpresaId },
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setCustosPorEmpresa(resposta.objetoResposta);
        return true;
      }
      return false;
    };

    const GetCustosPorCampoAlocado = async () => {
      const resposta = await Requicicao.Get({
        endpoint: `/Funcionarios/CustosPorAlocado`,
        params: { empresaId: EmpresaId },
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setCustosPorAlocado(resposta.objetoResposta);
        return true;
      }
      return false;
    };

    const GetTurnOver = async () => {
      const resposta = await Requicicao.Get({
        endpoint: `/Funcionarios/TurnOver`,
        params: {
          empresaId: EmpresaId,
          data: MaskUtil.applyDataUSMask(new Date())
        },
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setTurnOver(resposta.objetoResposta);
        return true;
      }
      return false;
    };

    const GetDocumentoEmpresaMensal = async () => {
      const resposta = await Requicicao.Get({
        endpoint: "/Funcionarios/GetDocumentoEmpresaMensal",
        params: {
          empresaId: EmpresaId,
          data: MaskUtil.applyDataUSMask(new Date())
        },
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setDocumentoMensal(resposta.objetoResposta);
        return true;
      }
      return false;
    };

    const GetControleFerias = async () => {
      const resposta = await Requicicao.Get({
        endpoint: "/Funcionarios/ControleFerias",
        params: {
          empresaId: EmpresaId
        },
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        setControleFerias(resposta.objetoResposta);
        return true;
      }
      return false;
    };

    const GetHistoricoAdmissaoDesligado = async () => {
      const CalcularStatusPorMes = (data) => {
        const resultado = {};
      
        data.forEach((funcionario) => {
          const { statuses } = funcionario;
      
          // Filtra apenas os status 1 (Ativo) e 2 (Desligado)
          const ativos = statuses.filter((x) => x.status === 1);
          const desligados = statuses.filter((x) => x.status === 2);
      
          const primeiraAtivo = ativos.length
            ? ativos.reduce((prev, curr) =>
                new Date(prev.dataInicio) < new Date(curr.dataInicio) ? prev : curr
              )
            : null;
      
          const ultimaDesligado = desligados.length
            ? desligados.reduce((prev, curr) =>
                new Date(prev.dataInicio) > new Date(curr.dataInicio) ? prev : curr
              )
            : null;
      
          if (primeiraAtivo) {
            const mes = new Date(primeiraAtivo.dataInicio).toISOString().slice(0, 7); // yyyy-mm
            if (!resultado[mes]) resultado[mes] = [];
            const existe = resultado[mes].find((r) => r.texto === "Ativo");
            if (existe) existe.valor += 1;
            else resultado[mes].push({ texto: "Ativo", valor: 1 });
          }
      
          if (ultimaDesligado) {
            const mes = new Date(ultimaDesligado.dataInicio).toISOString().slice(0, 7); // yyyy-mm
            if (!resultado[mes]) resultado[mes] = [];
            const existe = resultado[mes].find((r) => r.texto === "Desligado");
            if (existe) existe.valor += 1;
            else resultado[mes].push({ texto: "Desligado", valor: 1 });
          }
        });
      
        return resultado;
      };

      const FormatarResultado = (dados) =>
        Object.entries(dados).map(([mes, contagem]) => ({
          mes,
          contagem
        }));

      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/AllInfoById",
        params: { id: EmpresaId },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        const ListaFunci = data.objetoResposta;

        const lista = ListaFunci.map((item) => {
          const ativosEDesligados = item.statuses.filter(
            (stat) =>
              stat.status === 1 || stat.status === 2
          );
        
          console.log(ativosEDesligados);
          let primeiraAtivo = null;
          let ultimaDesligado = null;
        
          ativosEDesligados.forEach((stat) => {
            const dataInicio = new Date(stat.dataInicio);
            if (stat.status === 1) {
              if (!primeiraAtivo || new Date(primeiraAtivo.dataInicio) > dataInicio) {
                primeiraAtivo = stat;
              }
            }
            if (stat.status === 2) {
              if (!ultimaDesligado || new Date(ultimaDesligado.dataInicio) < dataInicio) {
                ultimaDesligado = stat;
              }
            }
          });
        
          const statusFinal = [];
          if (primeiraAtivo) statusFinal.push(primeiraAtivo);
          if (ultimaDesligado) statusFinal.push(ultimaDesligado);
        
          return {
            ...item,
            statuses: statusFinal
          };
        });

        const resultado = CalcularStatusPorMes(lista);
        setDataGrafico(FormatarResultado(resultado));

        return true;
      }
      return false;
    };

    const Go = async () => {
      try {
        setLoading(true);
        if (EmpresaId != null) {
          await GetFuncionarioEmpresa();
          await GetFuncionarioAlocados();
          await GetCustosPorCampoEmpresa();
          await GetCustosPorCampoAlocado();
          await GetTurnOver();
          await GetDocumentoEmpresaMensal();
          await GetControleFerias();
          await GetHistoricoAdmissaoDesligado();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    Go();
  }, [EmpresaId]);

  useEffect(() => {
    if (query.get("id") != null) {
      setEmpresaId(query.get("id"));
    }
  }, [query]);

  useEffect(() => {
    setDocFlatando(GetDocFlatando(DocumentoMensal));
  }, [DocumentoMensal]);

  const SortText = (a, b) => {
    if (a.texto < b.texto) {
      return -1;
    }
    if (a.texto > b.texto) {
      return 1;
    }
    return 0;
  };

  return (
    <div className="row g-3">
      <Dialog
        header={NameSelectedImage}
        visible={ShowMoldaPdf}
        onHide={() => setShowMoldaPdf(false)}
      >
        <PDFPreview file={PdfFile} />
      </Dialog>
      <div className="col-12">
        <div className="card p-3">
          <h2 className="fs-4 text-center mb-4">
            Historico Admissao e Desligado
          </h2>
          <GraficoHistoricoAdmissaoDesligado DataGrafico={DataGrafico} />
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6">
        <div className="card p-3">
          <h2 className="fs-4 text-center mb-4">Funcionarios Contratados</h2>
          {ListaFuncionarioEmpresa.length > 0 ? (
            <Apexcharts
              data={{
                type: "column",
                labels: [""],
                datasets: ListaFuncionarioEmpresa.sort(SortText).map((x) => ({
                  name: x.texto,
                  data: [x.quantidade]
                }))
              }}
            />
          ) : (
            <p className="m-0">Sem dados</p>
          )}
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6">
        <div className="card p-3">
          <h2 className="fs-4 text-center mb-4">Funcionarios Alocados</h2>
          {ListaFuncionarioAlocados.length > 0 ? (
            <Apexcharts
              data={{
                type: "column",
                labels: [""],
                datasets: ListaFuncionarioAlocados.sort(SortText).map((x) => ({
                  name: x.texto,
                  data: [x.quantidade]
                }))
              }}
            />
          ) : (
            <p className="m-0">Sem dados</p>
          )}
        </div>
      </div>
      {[
        {
          title: "Funcionários Contratados",
          data: CustosPorEmpresa
        },
        {
          title: "Funcionários Alocados",
          data: CustosPorAlocado
        }
      ].map(({ title, data }, index) => (
        <div className="col-sm-12 col-md-6" key={index}>
          <div className="card p-3">
            <h2 className="fs-4 text-center mb-4">{title}</h2>
            <table className="table table-striped">
              <tbody>
                <tr>
                  <th>Salário</th>
                  <td>{MaskUtil.applyMonetaryMask(data.salario)}</td>
                </tr>
                <tr>
                  <th>Vale Alimentação</th>
                  <td>{MaskUtil.applyMonetaryMask(data.valeAlimentacao)}</td>
                </tr>
                <tr>
                  <th>Vale Refeição</th>
                  <td>{MaskUtil.applyMonetaryMask(data.valeRefeicao)}</td>
                </tr>
                <tr>
                  <th>Vale Transporte</th>
                  <td>{MaskUtil.applyMonetaryMask(data.valeTransporte)}</td>
                </tr>
                <tr>
                  <th>Plano de Saúde</th>
                  <td>{MaskUtil.applyMonetaryMask(data.planoSaude)}</td>
                </tr>
                <tr>
                  <th>Plano Odontológico</th>
                  <td>{MaskUtil.applyMonetaryMask(data.planoOdontologico)}</td>
                </tr>
                <tr>
                  <th>Seguro de Vida</th>
                  <td>{MaskUtil.applyMonetaryMask(data.seguroVida)}</td>
                </tr>
                <tr>
                  <th>Auxílio Creche</th>
                  <td>{MaskUtil.applyMonetaryMask(data.auxilioCreche)}</td>
                </tr>
                <tr>
                  <th>Gympass/Academia</th>
                  <td>{MaskUtil.applyMonetaryMask(data.valeAcademia)}</td>
                </tr>
                <tr>
                  <th>Auxílio Home Office</th>
                  <td>{MaskUtil.applyMonetaryMask(data.auxilioHomeOffice)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))}
      <div className="col-sm-12 col-md-12 col-lg-6">
        <div className="card p-3">
          <h2 className="fs-4 text-center mb-4">Turn-over</h2>
          <table className="table table-striped">
            <tbody>
              <tr>
                <th>Mensal</th>
                <td>%{TurnOver.turnoverMensal.toFixed(2)}</td>
              </tr>
              <tr>
                <th>Trimestral</th>
                <td>%{TurnOver.turnoverTrimestral.toFixed(2)}</td>
              </tr>
              <tr>
                <th>Anual</th>
                <td>%{TurnOver.turnoverAnual.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6">
        <div className="card p-3">
          <div>
            <h2 className="fs-4 text-center mb-4">Documentos do mês</h2>
            {DocFlatando.length > 0 && (
              <div className="alert alert-warning" role="alert">
                <div className="w-100 d-flex flex-row justify-content-between align-items-center">
                  <p className="m-0">
                    <i className="bi bi-exclamation-circle" /> Pendências de{" "}
                    {DocFlatando.length} arquivos
                  </p>
                  <Button
                    className="btn"
                    label="Visualizar"
                    onClick={(e) => op.current.toggle(e)}
                  />
                </div>
                <OverlayPanel ref={op}>
                  <ul className="m-0">
                    {DocFlatando.map((item) => (
                      <li key={item}>
                        {TipoMensal.find((x) => x.value === item)?.label}
                      </li>
                    ))}
                  </ul>
                </OverlayPanel>
              </div>
            )}
          </div>
          <DataTable value={DocumentoMensal}>
            <Column
              field="tipoMensal"
              header="Tipo"
              body={(row) =>
                TipoMensal.find((x) => x.value === row.tipoMensal)?.label
              }
            />
            <Column
              field="dataCriacao"
              header="Data de criação"
              body={(row) => MaskUtil.applyDataMask(row.dataCriacao)}
            />
            <Column
              header="Ação"
              body={(row) => (
                <div className="d-flex gap-3">
                  <Button
                    label="Visualizar"
                    icon="pi pi-eye"
                    onClick={() => HandleShowDocumento(row.id, row.nome)}
                  />
                  <Button
                    label="Baixar"
                    icon="pi pi-download"
                    onClick={() => HandleDownloadDocumento(row.id, row.nome)}
                  />
                </div>
              )}
            />
          </DataTable>
        </div>
      </div>
      <div className="col-sm-12 col-md-12 col-lg-6">
        <div className="card p-3">
          <h2 className="fs-4 text-center mb-4">Controle de Ferias</h2>
          {ControleFerias.totalAnos != null && (
            <Apexcharts
              data={{
                type: "pie",
                labels: ["#Ferias Acumuladas", "#Ferias Utilizadas"],
                datasets: [
                  {
                    data: [
                      ControleFerias.totalDias - ControleFerias.totalDiasUsados,
                      ControleFerias.totalDiasUsados
                    ]
                  }
                ]
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default IndicadoresRH;
