import React from "react";

import PropTypes from "prop-types";

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { InputText } from "primereact/inputtext";

import MaskUtil from "utils/MaskUtil";

import InputDateSomentePicker from "components/RH/inputs/InputDateSomentePicker";

import ptBR from "date-fns/locale/pt-BR";

registerLocale("pt-BR", ptBR);

const DadosPessoais = ({ dados, setDados, HandleChange, BoxBtns }) => (
  <div className="row g-3">
    <div className="col-md-3">
      <label htmlFor="nome" className="form-label">
        Nome
      </label>
      <InputText
        id="nome"
        name="nome"
        value={dados.nome}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-3">
      <label htmlFor="cpf" className="form-label">
        CPF
      </label>
      <InputText
        id="cpf"
        name="cpf"
        value={dados.cpf}
        onChange={(e) =>
          setDados({
            ...dados,
            cpf: MaskUtil.applyCpfMask(e.target.value)
          })
        }
        className="form-control"
      />
    </div>
    <div className="col-md-3">
      <label htmlFor="rg" className="form-label">
        RG
      </label>
      <InputText
        id="rg"
        name="rg"
        value={dados.rg}
        onChange={(e) =>
          setDados({
            ...dados,
            rg: MaskUtil.applyRgMask(e.target.value)
          })
        }
        className="form-control"
      />
    </div>
    <div className="col-md-3">
      <label htmlFor="cnh" className="form-label">
        CNH
      </label>
      <InputText
        id="cnh"
        name="cnh"
        value={dados.cnh}
        onChange={(e) =>
          setDados({
            ...dados,
            cnh: MaskUtil.applyCnhMask(e.target.value)
          })
        }
        className="form-control"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="dataNascimento" className="form-label">
        Data de Nascimento
      </label>
      <DatePicker
        id="dataNascimento"
        selected={dados.dataNascimento}
        onChange={(date) =>
          setDados((prev) => ({ ...prev, dataNascimento: date }))
        }
        dateFormat="dd/MM/yyyy"
        className="form-control w-100 custom-datepicker"
        customInput={<InputDateSomentePicker />}
        locale="pt-BR"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
    <div className="col-md-8">
      <label htmlFor="endereco" className="form-label">
        Endereço
      </label>
      <InputText
        id="endereco"
        name="endereco"
        value={dados.endereco}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-6">
      <label htmlFor="email" className="form-label">
        Email
      </label>
      <InputText
        id="email"
        name="email"
        value={dados.email}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-6">
      <label htmlFor="telefone" className="form-label">
        Telefone
      </label>
      <InputText
        id="telefone"
        name="telefone"
        value={dados.telefone}
        onChange={(e) =>
          setDados({
            ...dados,
            telefone: MaskUtil.applyTelefoneMask(e.target.value)
          })
        }
        className="form-control"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="pis" className="form-label">
        PIS
      </label>
      <InputText
        id="pis"
        name="pis"
        value={dados.pis}
        className="form-control"
        disabled
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="carteiraTrabalho" className="form-label">
        Carteira de Trabalho
      </label>
      <InputText
        id="carteiraTrabalho"
        name="carteiraTrabalho"
        value={dados.carteiraTrabalho}
        className="form-control"
        disabled
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="tituloEleitor" className="form-label">
        Título de Eleitor
      </label>
      <InputText
        id="tituloEleitor"
        name="tituloEleitor"
        value={dados.tituloEleitor}
        className="form-control"
        disabled
      />
    </div>

    <div className="col-12">
      <BoxBtns />
    </div>
  </div>
);

DadosPessoais.propTypes = {
  dados: PropTypes.shape({
    nome: PropTypes.string,
    cpf: PropTypes.string,
    rg: PropTypes.string,
    cnh: PropTypes.string,
    dataNascimento: PropTypes.instanceOf(Date),
    endereco: PropTypes.string,
    email: PropTypes.string,
    telefone: PropTypes.string,
    pis: PropTypes.string,
    carteiraTrabalho: PropTypes.string,
    tituloEleitor: PropTypes.string
  }).isRequired,
  setDados: PropTypes.func.isRequired,
  HandleChange: PropTypes.func.isRequired,
  BoxBtns: PropTypes.elementType.isRequired
};

export default DadosPessoais;
