import { useState, useEffect } from "react";
import {
  FaUser,
  FaHome,
  FaBaby,
  FaSmile,
  FaPlane,
  FaMedkit,
  FaBuilding,
  FaBriefcase,
  FaCalendarAlt,
  FaTimesCircle,
  FaPauseCircle,
  FaCheckCircle,
  FaExclamationCircle
} from "react-icons/fa";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";
import useQuery from "utils/useQuery";

const InfoUser = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const query = useQuery();
  const Requicicao = new Api();

  const statusIcons = {
    0: { icon: FaSmile, color: "text-primary", label: "Não Contratado" },
    1: { icon: FaCheckCircle, color: "text-success", label: "Ativo" },
    2: { icon: FaTimesCircle, color: "text-danger", label: "Desligado" },
    3: { icon: FaPauseCircle, color: "text-warning", label: "Em Desligamento" },
    4: { icon: FaMedkit, color: "text-info", label: "Afastado por Doença" },
    5: { icon: FaPlane, color: "text-primary", label: "Afastado por Férias" },
    6: { icon: FaBaby, color: "text-pink", label: "Afastado por Maternidade" }
  };

  const [dados, setDados] = useState({});

  const GetConta = async (id) => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/Funcionarios/Info",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.codigoEstadoHttp === 200) {
        setDados(data.objetoResposta);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = query.get("id");
    if (auth.isAuthenticated() && id != null) GetConta(id);
  }, [auth, query]);

  const RenderStatus = () => {
    const status = statusIcons[dados.status];
    if (status) {
      const Icon = status.icon;
      return (
        <div className="list-group-item d-flex align-items-center">
          <Icon className={`me-2 ${status.color}`} />
          <div>
            <label className="fw-semibold text-secondary">Status:</label>
            <p className="m-0 ms-1">
              <span className="text-muted">{status.label}</span>
            </p>
          </div>
        </div>
      );
    }
    return (
      <div className="list-group-item d-flex align-items-center">
        <FaExclamationCircle className="me-2 text-warning" />
        <div>
          <label className="fw-semibold text-secondary">Status:</label>
          <p className="m-0 ms-1">
            <span className="text-muted">Status não disponível</span>;
          </p>
        </div>
      </div>
    );
  };

  return (
    <div className="card p-4 shadow-lg border-0 rounded">
      <div className="list-group">
        <div className="list-group-item d-flex align-items-center">
          <FaUser className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">Nome:</label>
            <p className="m-0 ms-1">
              {dados.nome || (
                <span className="text-muted">Informação não disponível</span>
              )}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaCalendarAlt className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Data de Nascimento:
            </label>
            <p className="m-0 ms-1">
              {dados.dataNascimento ? (
                MaskUtil.applyDataMask(dados.dataNascimento)
              ) : (
                <span className="text-muted">Informação não disponível</span>
              )}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaCalendarAlt className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Data de Admissão:
            </label>
            <p className="m-0 ms-1">
              {dados.dataAdmissao &&
              dados.dataAdmissao !== "0001-01-01T00:00:00" ? (
                MaskUtil.applyDataMask(dados.dataAdmissao)
              ) : (
                <span className="text-muted">Não admitido</span>
              )}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaBriefcase className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">Cargo:</label>
            <p className="m-0 ms-1">
              {dados.cargo || (
                <span className="text-muted">Informação não disponível</span>
              )}
            </p>
          </div>
        </div>

        <RenderStatus />

        <div className="list-group-item d-flex align-items-center">
          <FaBuilding className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Empresa Contratada:
            </label>
            <p className="m-0 ms-1">
              {dados.empresaContratadaNome || (
                <span className="text-muted">Informação não disponível</span>
              )}
              {dados.empresaContratadaCodigoLoja &&
                ` (Código: ${dados.empresaContratadaCodigoLoja})`}
            </p>
          </div>
        </div>

        <div className="list-group-item d-flex align-items-center">
          <FaHome className="me-2 text-secondary" />
          <div>
            <label className="fw-semibold text-secondary">
              Empresa Alocado:
            </label>
            <p className="m-0 ms-1">
              {dados.empresaAlocadoNome || (
                <span className="text-muted">Informação não disponível</span>
              )}
              {dados.empresaAlocadoCodigoLoja &&
                ` (Código: ${dados.empresaAlocadoCodigoLoja})`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoUser;
