const StatusCadastroFuncionari = [
  "Cadastro Iniciado",
  "Convite Enviado",
  "Aguardando Preenchimento",
  "Documentos Submetidos, Aguardando Avaliação",
  "Documentos Aprovados",
  "Documentos Reprovados, Reenvio Necessário",
  "Aguardando Assinatura Eletrônica",
  "Processo Concluído"
]

const StatusCadastroFuncionariV2 = [
  { label: "Cadastro Iniciado", value: 1 },
  { label: "Convite Enviado", value: 2 },
  { label: "Aguardando Preenchimento", value: 3 },
  { label: "Documentos em Avaliação", value: 4 },
  { label: "Documentos Aprovados", value: 5 },
  { label: "Documentos Reprovados", value: 6 },
  { label: "Aguardando Assinatura", value: 7 },
  { label: "Processo Concluído", value: 8 },
]

const TiposDocs = [
  { name: "dadosPessoais", label: "Dados pessoais" },
  { name: "cnh", label: "CNH" },
  { name: "foto", label: "Foto" },
  { name: "arquivosContratacao", label: "Arquivos da contratação" },
  { name: "escolaridade", label: "Escolaridade" },
  { name: "exameAdmissional", label: "Exame admissional" },
  { name: "endereco", label: "Endereço" },
  { name: "pensaoAlimenticia", label: "Pensão alimentícia" },
  { name: "dadosBancarios", label: "Dados bancários" },
  { name: "carteiraTrabalho", label: "Carteira de trabalho" },
  { name: "pisEsocial", label: "PIS/Esocial" },
  { name: "reservista", label: "Reservista" },
  { name: "uniforme", label: "Uniforme" },
  { name: "dependentes", label: "Dependentes" },
  { name: "tituloEleitor", label: "Título de eleitor" },
  { name: "rgCpf", label: "RG/CPF" }
];

const TipoMensal = [
  { label: "FOLHA DE PAGAMENTO (ARQUIVO COMPLETO)", value: 1 },
  { label: "COMPROVANTE DE PAGAMENTO DE SALÁRIO", value: 2 },
  { label: "GUIA DE FGTS DIGITAL + DETALHE DA GUIA EMITIDA", value: 3 },
  { label: "COMPROVANTE DE PAGAMENTO DO FGTS", value: 4 },
  { label: "GUIA DO INSS", value: 5 },
  { label: "COMPROVANTE DE PAGAMENTO DO INSS", value: 6 },
  { label: "FOLHA DE PONTO ELETRÔNICO", value: 7 },
  { label: "RELATÓRIO COMPRA DO VALE TRANSPORTE", value: 8 },
  { label: "COMPROVANTE DE PAGAMENTO DO VALE TRANSPORTE", value: 9 },
  { label: "RELATÓRIO COMPRA DO VALE REFEIÇÃO", value: 10 },
  { label: "COMPROVANTE DE PAGAMENTO DO VALE REFEIÇÃO", value: 11 },
  { label: "RECIBO DE FÉRIAS", value: 12 },
  { label: "COMPROVANTE DE PAGAMENTO DE FÉRIAS", value: 13 },
  { label: "RESCISÃO", value: 14 },
  { label: "COMPROVANTE DE PAGAMENTO RESCISÃO", value: 15 }
];

const TipoAnual = [
  { label: "PPRA (Programa de Controle Médico de Saúde Ocupacional)", value: 1 },
  { label: "PGR (Programa de Gerenciamento de Riscos)", value: 2 },
  { label: "Certidão de Regularidade do FGTS (CRF)", value: 3 },
  { label: "Certidão da Procuradoria e da Receita Federal (INSS)", value: 4 },
  { label: "Certidão Municipal", value: 5 },
  { label: "Certidão Estadual", value: 6 },
  { label: "Certidão Trabalhista", value: 7 }
];

const StatusFuncionario = [
  { label: "Não Contratado", value: 0 },
  { label: "Ativo", value: 1 },
  { label: "Desligado", value: 2 },
  { label: "Desligamento", value: 3 },
  { label: "Doença", value: 4 },
  { label: "Férias", value: 5 },
  { label: "Licença Maternidade/Paternidade", value: 6 },
]

const TipoComissao = [
  { label: "Sem Comissão", value: 0 },
  { label: "Fixa", value: 1 },
  { label: "Variável", value: 2 },
]

const TipoEscala = [
  { label: "Escala 5x2", value: 1 },
  { label: "Escala 6x1", value: 2 },
  { label: "Escala 12x36", value: 3 },
  { label: "Escala 24x48", value: 4 },
  { label: "Escala de Turnos (ou Revezamento)", value: 5 },
  { label: "Escala de Plantão", value: 6 },
  { label: "Escala de Sobreaviso", value: 7 },
]

const MotivoDesligamentoOptions = [
  { label: "Pedido de Demissão", value: "pedidoDemissao" },
  { label: "Acordo entre as Partes", value: "acordo" },
  { label: "Demissão por Justa Causa", value: "demissao justa causa" },
  { label: "Demissão sem Justa Causa", value: "demissao sem justa causa" },
  { label: "Rescisão Antecipada do Contrato de Experiência – pedido da empresa", value: "pedido empresa rescisao antecipada contrato experiencia" },
  { label: "Rescisão Antecipada do Contrato de Experiência – pedido do empregado", value: "pedido empregado rescisao antecipada contrato experiencia" },
  { label: "Término do Contrato de Experiência", value: "termino contrato experiencia" },
  { label: "Término do Contrato por prazo Determinado", value: "termino contrato prazo determinado" },
  { label: "Rescisão Antecipada do Contrato de Estágio – pedido empresa", value: "pedido empresa rescisao antecipada contrato estagio" },
  { label: "Rescisão Antecipada do Contrato de Estágio – pedido estagiário", value: "pedido estagiario rescisao antecipada contrato estagio" },
  { label: "Suspensão de Contrato", value: "suspensao contrato" },
  { label: "Morte", value: "morte" },
];

const AvisoPrevioOptions = [
  { label: "Indenizado", value: "indenizado" },
  { label: "Trabalhado", value: "trabalhado" },
  { label: "Não aplicável", value: "nao aplicavel" }
];

const Permissoes = [
  { label: "RH", value: 60 },
  { label: "Funcionario", value: 70 },
]

const StatusOcorrencia = [
  { label: "Atraso", value: 2 },
  { label: "Falta", value: 1 },
  { label: "Suspensão", value: 3 },
  { label: "Outra", value: 0 }
]

export default {
  MotivoDesligamentoOptions,
  StatusCadastroFuncionariV2,
  StatusCadastroFuncionari,
  AvisoPrevioOptions,
  StatusFuncionario,
  StatusOcorrencia,
  TipoComissao,
  Permissoes,
  TipoEscala,
  TipoMensal,
  TiposDocs,
  TipoAnual
}