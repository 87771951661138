/* eslint-disable no-nested-ternary */
import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Card } from "primereact/card";
import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";
import { useNavigate } from "react-router-dom";
import ExportMonetaryToExcel from "components/ExportToExcel/ExportMonetaryToExcel";

const ListaVendasCanceladas = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const navigate = useNavigate();

  const [listaCanceladasPeriodo, setListaCanceladasPeriodo] = useState([]);
  const [listaCanceladasOutrosPeriodos, setListaCanceladasOutrosPeriodos] = useState([]);
  const [ListaBase, setListaBase] = useState([]);
  const [codigosLojas, setCodigosLojas] = useState([]);
  const [codigoLojaSelecionado, setCodigoLojaSelecionado] = useState([]);

  const getPrimeiroDiaDoMes = () => {
    const hoje = new Date();
    return new Date(hoje.getFullYear(), hoje.getMonth(), 1).toISOString().split("T")[0];
  };

  const [dataInicio, setDataInicio] = useState(getPrimeiroDiaDoMes());
  const [dataFim, setDataFim] = useState(new Date().toISOString().split("T")[0]);

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const params = { dataInicio, dataFim, tipoBusca: "Cancelados" };
  
      const resposta = await Requicicao.Get({
        endpoint: "/Venda/ObterTodosResumoDados",
        params,
        config: auth.GetHeaders(),
      });
  
      if (resposta?.codigoEstadoHttp === 200) {
        const vendasCanceladas = resposta.objetoResposta.map((itemVenda) => {
          // Soma os valores dos produtos
          const valorTotalLiquido = itemVenda.produtos.reduce(
            (total, produto) => total + (produto.valorTotalLiquido ?? 0),
            0
          );
  
          // Filtra apenas os meios de pagamento cancelados
          const meiosPagamentosCancelados = itemVenda.meiosPagamentos.filter((mp) =>
            mp.statusDescricao.toLowerCase().includes("cancelado")
          );
  
          // Captura a data do cancelamento, pegando a menor data dos pagamentos cancelados
          const dataCancelamento =
            meiosPagamentosCancelados.length > 0
              ? new Date(
                  Math.min(...meiosPagamentosCancelados.map((mp) => new Date(mp.data).getTime()))
                ).toISOString()
              : null;
  
          return {
            id: itemVenda.id,
            numeroVenda: itemVenda.numeroVenda,
            codigoLoja: itemVenda.codigoLoja,
            nomeEmpresa: itemVenda.nomeEmpresa,
            nomeVendedor: itemVenda.nomeVendedor,
            valorTotalLiquido, // Agora corretamente calculado
            dataCriacao: itemVenda.dataCriacao,
            dataCancelamento,
            itensProdutos: itemVenda.produtos,
            meiosPagamentos: meiosPagamentosCancelados.map((pagamento) => ({
              formaPagamento: pagamento.formaPagamento,
              valorPago: pagamento.valorPago,
            })),
          };
        });
  
        setListaBase(vendasCanceladas);

        // Captura os códigos das lojas únicas e os define no estado
        const codigosUnicos = [...new Set(vendasCanceladas.map(venda => venda.codigoLoja))];
        setCodigosLojas(codigosUnicos);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
  }, [auth, dataInicio, dataFim]);

  useEffect(() => {
    const listaFiltrada = ListaBase.filter(
      (item) =>
        codigoLojaSelecionado.length === 0 || codigoLojaSelecionado.includes(item.codigoLoja)
    );
  
    const listaPeriodo = listaFiltrada.filter((item) => {
      if (!item.dataCriacao) return false; // Garante que tem data de criação
  
      const dataVenda = new Date(item.dataCriacao).toISOString().split("T")[0];
  
      return dataVenda >= dataInicio && dataVenda <= dataFim;
    });
  
    const listaOutrosPeriodos = listaFiltrada.filter((item) => {
      if (!item.dataCriacao) return false;
  
      const dataVenda = new Date(item.dataCriacao).toISOString().split("T")[0];
  
      return dataVenda < dataInicio;
    });
  
    setListaCanceladasPeriodo(listaPeriodo);
    setListaCanceladasOutrosPeriodos(listaOutrosPeriodos);
    console.log(listaOutrosPeriodos);
  }, [ListaBase, dataInicio, dataFim, codigoLojaSelecionado]);
  

  const calcularTotais = (lista) => {
    const totalVendas = lista.length;
    const totalValor = lista.reduce((total, item) => total + item.valorTotalLiquido, 0);
  
    return {
      totalVendas,
      totalValor: totalValor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    };
  };
  
  const totaisPeriodo = calcularTotais(listaCanceladasPeriodo);
  const totaisOutrosPeriodos = calcularTotais(listaCanceladasOutrosPeriodos);

  const getExportDataPeriodo = () =>
    listaCanceladasPeriodo.map(item => ({
      "Número da Venda": item.numeroVenda,
      "Código Loja": item.codigoLoja,
      "Empresa": item.nomeEmpresa,
      "Vendedor": item.nomeVendedor,
      "Produtos": item.itensProdutos
        .map(produto => `${produto.quantidadeProduto}x ${produto.produtoDescricao} (R$ ${produto.valorTotalLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })})`)
        .join("; "),
      "Valor Total Líquido": `R$ ${item.valorTotalLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
      "Data da Venda": MaskUtil.applyDataMask(item.dataCriacao),
      "Data do Cancelamento": item.dataCancelamento ? MaskUtil.applyDataMask(item.dataCancelamento) : "",
    }));
  
  const getExportDataOutrosPeriodos = () =>
    listaCanceladasOutrosPeriodos.map(item => ({
      "Número da Venda": item.numeroVenda,
      "Código Loja": item.codigoLoja,
      "Empresa": item.nomeEmpresa,
      "Vendedor": item.nomeVendedor,
      "Produtos": item.itensProdutos
        .map(produto => `${produto.quantidadeProduto}x ${produto.produtoDescricao} (R$ ${produto.valorTotalLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })})`)
        .join("; "),
      "Valor Total Líquido": `R$ ${item.valorTotalLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`,
      "Data da Venda": MaskUtil.applyDataMask(item.dataCriacao),
      "Data do Cancelamento": item.dataCancelamento ? MaskUtil.applyDataMask(item.dataCancelamento) : "",
    }));
  
  const getFileNamePeriodo = () => `Vendas_Canceladas_Periodo_${new Date().toISOString().split("T")[0]}`;
  const getFileNameOutrosPeriodos = () => `Vendas_Canceladas_OutrosPeriodos_${new Date().toISOString().split("T")[0]}`;

  return (
    <div className="card p-3">
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex gap-3">
          <Button label="Voltar" icon="pi pi-arrow-left" className="p-button-secondary" onClick={() => navigate("/app/vendasPorProduto")} />
        </div>
        <div className="d-flex gap-3">
          <InputText type="date" value={dataInicio} onChange={(e) => setDataInicio(e.target.value)} />
          <InputText type="date" value={dataFim} onChange={(e) => setDataFim(e.target.value)} />
          <MultiSelect
            value={codigoLojaSelecionado}
            options={codigosLojas.map((codigo) => ({ label: codigo, value: codigo }))}
            onChange={(e) => setCodigoLojaSelecionado(e.value)}
            placeholder="Selecione Lojas"
          />
        </div>
      </div>

      <Card title="Vendas Canceladas - Dentro do Período">
        <div className="d-flex justify-content-between mb-3">
          <ExportMonetaryToExcel data={getExportDataPeriodo()} fileName={getFileNamePeriodo()} />
        </div>
        <div className="mt-2 p-2 text-end">
          <strong>Total de Vendas Canceladas: {totaisPeriodo.totalVendas}</strong>
          <br />
          <strong>Valor Total: {totaisPeriodo.totalValor}</strong>
        </div>
        <DataTable value={listaCanceladasPeriodo} paginator rows={10} emptyMessage="Nenhuma venda cancelada no período.">
          <Column field="nomeEmpresa" header="Empresa" />
          <Column field="codigoLoja" header="Código Loja" />
          <Column field="numeroVenda" header="Número da Venda" />
          <Column 
            field="itensProdutos" 
            header="Produtos" 
            body={(rowData) => rowData.itensProdutos.map(
              (produto) => `${produto.quantidadeProduto}x ${produto.produtoDescricao} (R$ ${produto.valorTotalLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })})`
            ).join("\n")}
            style={{ whiteSpace: "pre-line" }} 
          />
          <Column field="nomeVendedor" header="Vendedor" />
          <Column field="valorTotalLiquido" header="Valor" body={(rowData) => {
            const valor = rowData.valorTotalLiquido ?? 0; // Garante que sempre há um valor numérico
            return `${valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`;
          }} />
          <Column field="dataCriacao" header="Data Venda" body={(rowData) => MaskUtil.applyDataMask(rowData.dataCriacao)} />
          <Column field="dataCancelamento" header="Data Cancelamento" body={(rowData) => rowData.dataCancelamento ? MaskUtil.applyDataMask(rowData.dataCancelamento) : ""} />
        </DataTable>
      </Card>

      <Card title="Vendas Canceladas - Outros Períodos" className="mt-3">
        <div className="d-flex justify-content-between mb-3">
          <ExportMonetaryToExcel data={getExportDataOutrosPeriodos()} fileName={getFileNameOutrosPeriodos()} />
        </div>
        <div className="mt-2 p-2 text-end">
          <strong>Total de Vendas Canceladas: {totaisOutrosPeriodos.totalVendas}</strong>
          <br />
          <strong>Valor Total: {totaisOutrosPeriodos.totalValor}</strong>
        </div>
        <DataTable value={listaCanceladasOutrosPeriodos} paginator rows={10} emptyMessage="Nenhuma venda cancelada em outros períodos.">
          <Column field="nomeEmpresa" header="Empresa" />
          <Column field="codigoLoja" header="Código Loja" />
          <Column field="numeroVenda" header="Número da Venda" />
          <Column 
            field="itensProdutos" 
            header="Produtos" 
            body={(rowData) => rowData.itensProdutos.map(
              (produto) => `${produto.quantidadeProduto}x ${produto.produtoDescricao} (R$ ${produto.valorTotalLiquido.toLocaleString('pt-BR', { minimumFractionDigits: 2 })})`
            ).join("\n")}
            style={{ whiteSpace: "pre-line" }} 
          />
          <Column field="nomeVendedor" header="Vendedor" />
          <Column field="valorTotalLiquido" header="Valor" body={(rowData) => {
            const valor = rowData.valorTotalLiquido ?? 0; // Garante que sempre há um valor numérico
            return `${valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`;
          }} />
          <Column field="dataCriacao" header="Data Venda" body={(rowData) => MaskUtil.applyDataMask(rowData.dataCriacao)} />
          <Column field="dataCancelamento" header="Data Cancelamento" body={(rowData) => rowData.dataCancelamento ? MaskUtil.applyDataMask(rowData.dataCancelamento) : ""} />

        </DataTable>
      </Card>
    </div>
  );
};

export default ListaVendasCanceladas;
