import { useState, useEffect, useRef } from "react";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import TrataRetornoApi from "utils/TrataRetornoApi";

const ListarPainelIndicadores = () => {
  const { setLoading } = useLoading();
  const notificacao = useNotification();
  const auth = useAuth();

  const requisicao = new Api();
  const tratarRetornoApi = new TrataRetornoApi();

  const [dadosNotasPendentes, setDadosNotasPendentes] = useState([]);
  const [dadosUltimoSincronismo, setDadosUltimoSincronismo] = useState([]);
  const [notasPendentesGlobalFilter, setNotasPendentesGlobalFilter] =
    useState("");
  const [ultimoSincronismoGlobalFilter, setUltimoSincronismoGlobalFilter] =
    useState("");
  const [modalDetalhes, setModalDetalhes] = useState(false);
  const [detalhesVenda, setDetalhesVenda] = useState([]);
  const [horasUltimoSinc, setHorasUltimoSinc] = useState(24);

  const dataTableNotasPendentesRef = useRef(null);
  const dataTableUltimoSincronismoRef = useRef(null);

  const colunasNotasPendentes = [
    { field: "operadorNome", header: "Operador" },
    { field: "lojaCodigo", header: "Loja" },
    { field: "balcaoNome", header: "Nome do Balcão" },
    { field: "balcaoCnpj", header: "CNPJ do Balcão" },
    { field: "quantidade", header: "Qtde" }
  ];
  const colunasUltimoSincronismo = [
    { field: "operadorNome", header: "Operador" },
    { field: "lojaCodigo", header: "Loja" },
    { field: "balcaoNome", header: "Nome do Balcão" },
    { field: "balcaoCnpj", header: "CNPJ do Balcão" },
    { field: "ultimaSincronizacao", header: "Última Sinronização" }
  ];

  const formatarData = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return date.toLocaleString("pt-BR");
  };

  const carregarIndicadores = async () => {
    try {
      setLoading(true);

      const resposta = await requisicao.Get({
        endpoint: "/PainelIndicadores",
        config: auth.GetHeaders()
      });

      if (resposta?.codigoEstadoHttp === 200) {
        setDadosNotasPendentes(
          resposta?.objetoResposta?.notasPendenteEmissaoPorLoja
        );
        setDadosUltimoSincronismo(
          resposta?.objetoResposta?.ultimosLogsSincronismoPorLoja
        );
      } else {
        notificacao({
          type: "erro",
          message: tratarRetornoApi.obterMensagemErro(resposta)
        });
      }
    } catch (error) {
      notificacao({
        type: "erro",
        message: tratarRetornoApi.obterMensagemErro(error)
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    carregarIndicadores();
  }, []);

  const abrirModalDetalhes = (vendasPendentes) => {
    setDetalhesVenda(vendasPendentes);
    setModalDetalhes(true);
  };

  const fecharModalDetalhes = () => {
    setModalDetalhes(false);
    setDetalhesVenda([]);
  };

  const exibirDetalhesTemplate = (rowData) => (
    <Button
      label=""
      icon="pi pi-search"
      className="p-button-text"
      style={{ height: "10px" }}
      onClick={() => abrirModalDetalhes(rowData.vendasComNotasPendentes)}
      disabled={!rowData.vendasComNotasPendentes?.length}
    />
  );

  return (
    <div>
      <div className="card p-3">
        {/* <div className="d-flex justify-content-between mb-3">
          <Button
            type="button"
            icon="pi pi-refresh"
            label="Atualizar"
            onClick={carregarIndicadores}
          /> */}
        {/* <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value || "")}
            placeholder="Pesquisar..."
            />
            </span> */}
        {/* </div> */}
        <div>
          <h4 style={{ textAlign: "center", color: "#00b08a" }}>
            Balcões com NFes ainda pendentes de emissão neste mês
          </h4>
        </div>
        <DataTable
          ref={dataTableNotasPendentesRef}
          value={dadosNotasPendentes || []}
          paginator
          rows={10}
          tableStyle={{ minWidth: "60rem" }}
          emptyMessage="Nenhum dado encontrado."
          globalFilter={notasPendentesGlobalFilter || ""}
        >
          {colunasNotasPendentes.map((col) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              sortable
            />
          ))}
          <Column
            header="Ações"
            body={exibirDetalhesTemplate}
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
      <br />
      <div className="card p-3">
        <div>
          <h4 style={{ textAlign: "center", color: "#00b08a" }}>
            Balcões com último sincronismo superior a {horasUltimoSinc} horas
          </h4>
        </div>
        <DataTable
          ref={dataTableUltimoSincronismoRef}
          value={dadosUltimoSincronismo || []}
          paginator
          rows={10}
          tableStyle={{ minWidth: "60rem" }}
          emptyMessage="Nenhum dado encontrado."
          globalFilter={ultimoSincronismoGlobalFilter || ""}
        >
          {colunasUltimoSincronismo.map((col) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              sortable
            />
          ))}
        </DataTable>
      </div>

      <Dialog
        header="Vendas com Notas Pendentes"
        visible={modalDetalhes}
        style={{ width: "40vw" }}
        onHide={fecharModalDetalhes}
      >
        {detalhesVenda.length > 0 ? (
          <ul>
            {detalhesVenda.map((venda) => (
              <li key={venda.id}>
                <strong>Número Venda:</strong> {venda.numero} <br />
                <strong>Data da Venda:</strong> {formatarData(venda.dataVenda)}
                <br />
                <br />
              </li>
            ))}
          </ul>
        ) : (
          <p>Nenhuma venda encontrada.</p>
        )}
      </Dialog>
    </div>
  );
};

export default ListarPainelIndicadores;
