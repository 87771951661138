import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useAuth } from "context/AuthContext";
import ExportToExcel from "components/ExportToExcel";

import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";
import Api from "utils/Api";

const ListaTransicaoLojas = () => {
  const { setLoading } = useLoading();
  const notify = useNotification();
  const auth = useAuth();
  const Requicicao = new Api();

  const [Lista, setLista] = useState([]);
  const [Colunas, setColunas] = useState([
    { field: "nomeOperador", header: "Nome do Operador" },
    { field: "codigoLoja", header: "Código Loja" },
    { field: "nomeLoja", header: "Nome da Loja" },
    { field: "dataInicio", header: "Data Início" },
    { field: "dataFim", header: "Data Fim" }
  ]);

  const formatDateTime = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const fetchTransicaoLojas = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/Venda/TransicaoLojas",
        config: auth.GetHeaders()
      });

      const formattedData = resposta.dados.map((item) => ({
        ...item,
        dataInicio: formatDateTime(item.dataInicio),
        dataFim: formatDateTime(item.dataFim)
      }));
      setLista(formattedData);
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao buscar transições de lojas."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransicaoLojas();
  }, []);

  const exportData = () => {
    // Função de exportação para Excel ou outro formato
    console.log("Exportando dados", Lista);
  };

  const totalTemplate = (rowData, column) => (
    <span>{rowData[column.field] || "Não Informado"}</span>
  );

  return (
    <div>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex gap-3">
            <ExportToExcel data={Lista} fileName="Transição Lojas" />{" "}
            {/* Use o componente aqui */}
            <Button
              type="button"
              icon="pi pi-refresh"
              label="Recarregar"
              onClick={fetchTransicaoLojas}
            />
          </div>
        </div>
        <DataTable
          value={Lista}
          stripedRows
          paginator
          rows={10}
          tableStyle={{ minWidth: "50rem" }}
          emptyMessage="Nenhuma transição de loja encontrada."
        >
          {Colunas.map((col) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              body={totalTemplate}
            />
          ))}
        </DataTable>
      </div>
    </div>
  );
};

export default ListaTransicaoLojas;
