import { useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";

const ModalNovoAcesso = ({
  ShowModalNovoAcesso,
  setShowModallNovoAcesso,
  Matriz,
  dados
}) => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const { Permissoes } = MaskEnum;

  const [DadosAcesso, setDadosAcesso] = useState(null);

  const [SelectedPermissao, setSelectedPermissao] = useState([70]);

  const GerarSenhaAleatoria = () => {
    let senha = "";
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 6; i++) {
      senha += Math.floor(Math.random() * 10); // Gera um número aleatório entre 0 e 9
    }
    return senha;
  };

  const CriaUser = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (SelectedPermissao.length > 0) {
        const data = {
          acesso: dados.email,
          senha: GerarSenhaAleatoria(),
          permissoes: SelectedPermissao,
          nomeCompletoOuRazaoSocial: dados.nome,
          apelidoOuNomeFantasia: dados.nome,
          cpfOuCnpj: MaskUtil.applyCpfMask(dados.cpf),
          empresaMatrizId: Matriz,
          FuncionarioId: dados.id
        };

        const response = await Requicicao.Post({
          endpoint: "/conta/ByFucionarios",
          data,
          config: auth.GetHeaders()
        });

        if (response.codigoEstadoHttp === 200) {
          if (response?.objetoResposta?.acesso != null) {
            setDadosAcesso({
              Acesso: response.objetoResposta.acesso,
              Senha: response.objetoResposta.senha
            });
          } else {
            setDadosAcesso({ Acesso: data.acesso, Senha: data.senha });
          }
        }
      } else {
        notify({ type: "aviso", message: "nenhuma permissão selecionada" });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      header="Novo Acesso criado"
      visible={ShowModalNovoAcesso}
      onHide={() => setShowModallNovoAcesso((e) => !e)}
    >
      {DadosAcesso == null ? (
        <form onSubmit={CriaUser}>
          <label className="form-label">Permissoes</label>
          <div className="card flex justify-content-center">
            <MultiSelect
              value={SelectedPermissao}
              onChange={(e) => setSelectedPermissao(e.value)}
              options={Permissoes}
              placeholder="Select Permissao"
              maxSelectedLabels={3}
              className="w-100"
              style={{ minWidth: "200px" }}
            />
          </div>
          <div className="d-flex flex-row-reverse mt-3">
            <Button label="Criar" className="btn btn-primary" />
          </div>
        </form>
      ) : (
        <>
          <p>
            <b>Acesso:</b> {DadosAcesso.Acesso}
          </p>
          <p>
            <b>Senha:</b> {DadosAcesso.Senha}
          </p>
        </>
      )}
    </Dialog>
  );
};

export default ModalNovoAcesso;
