import React, { useState } from "react";
import { useAuth } from "context/AuthContext";

import { Button } from "primereact/button";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProgressBar } from "primereact/progressbar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { useNotification } from "context/NotificationContext";
import Api from "utils/Api";
import * as XLSX from "xlsx";

const ImportarEstoque = ({
  ListaEmpresas,
  selectedEmpresa,
  onSelectEmpresa,
  onBack,
  onRefresh, // Novo parâmetro
}) => {
  const [file, setFile] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [progress, setProgress] = useState(0);
  const [validationError, setValidationError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false); // Controle do Modal
  const notify = useNotification();
  const Requicicao = new Api();

  const auth = useAuth();

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const validateExcel = async (worksheet) => {
    const requiredColumns = ["Codigo", "Quantidade"];
    const firstRow = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
    const rows = XLSX.utils.sheet_to_json(worksheet);
    const errors = [];

    // Verificar colunas obrigatórias
    requiredColumns.forEach((column) => {
      if (!firstRow.includes(column)) {
        errors.push(`Coluna obrigatória ausente: ${column}`);
      }
    });

    // Verificar inconsistências nas linhas
    rows.forEach((row, index) => {
      if (!row.Codigo) {
        errors.push(`Linha ${index + 1}: O campo "Codigo" está vazio.`);
      }
      if (!row.Quantidade || Number.isNaN(Number(row.Quantidade))) {
        errors.push(
          `Linha ${index + 1}: O campo "Quantidade" está vazio ou não é numérico.`
        );
      }
    });

    // Verificar existência dos produtos
    const productValidationPromises = rows.map(async (row, index) => {
      if (row.Codigo) {
        try {
          const response = await Requicicao.Get({
            endpoint: `/Produto/BuscarPorCodigoOuSkuFilial?codigoOuSku=${row.Codigo}&filialID=${selectedEmpresa.code}`,
            config: auth.GetHeaders(),
          });

          if (!response || response.status === 404) {
            errors.push(
              `Linha ${index + 1}: Produto com código "${row.Codigo}" não encontrado.`
            );
          }
        } catch (error) {
          console.error(
            `Erro ao validar produto na linha ${index + 1}:`,
            error
          );
          errors.push(
            `Linha ${index + 1}: Produto não encontrado "${row.Codigo}".`
          );
        }
      }
    });

    await Promise.all(productValidationPromises);

    return errors;
  };

  const handleUpload = async () => {
    if (!file || !selectedEmpresa) {
      notify({ type: "error", message: "Arquivo e Empresa são obrigatórios." });
      return;
    }
  
    try {
      const workbook = XLSX.read(await file.arrayBuffer(), { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
  
      // Etapa 1: Validar arquivo Excel e verificar produtos
      const errors = await validateExcel(worksheet);
      if (errors.length > 0) {
        setValidationError(errors.join("\n"));
        setShowErrorModal(true); // Exibir Modal de Erro
        return;
      }
  
      // Se não houver erros, obter ajustes
      const rows = XLSX.utils.sheet_to_json(worksheet);
      const adjustments = [];
      for (const row of rows) {
        try {
        // eslint-disable-next-line no-await-in-loop
          const response = await Requicicao.Get({
            endpoint: `/Produto/BuscarPorCodigoOuSkuFilial?codigoOuSku=${row.Codigo}&filialID=${selectedEmpresa.code}`,
            config: auth.GetHeaders(),
          });
  
          if (response && response.objetoResposta) {
            adjustments.push({
              ProdutoId: response.objetoResposta.id, // ProdutoId obtido
              EmpresaId: selectedEmpresa.code, // Empresa selecionada
              Quantidade: Number(row.Quantidade),
            });
          } else {
            throw new Error(
              `Produto com código "${row.Codigo}" não encontrado.`
            );
          }
        } catch (error) {
          console.error(`Erro ao buscar produto ${row.Codigo}:`, error);
          errors.push(
            `Erro ao buscar produto com código "${row.Codigo}": ${error.message}`
          );
        }
      }
  
      // Se houver erros na obtenção dos ajustes, exibir no modal
      if (errors.length > 0) {
        setValidationError(errors.join("\n"));
        setShowErrorModal(true);
        return;
      }
  
      // Etapa 2: Processar ajustes se não houver erros
      setProgress(0);
      setIsProcessing(true);
      const totalRecords = adjustments.length;
  
      const promises = adjustments.map((adjustment, index) =>
        Requicicao.Patch({
          endpoint: "/EstoquePorEmpresa/Ajustar",
          data: adjustment,
          config: auth.GetHeaders(),
        })
          .then(() => {
            setProgress(Math.round(((index + 1) / totalRecords) * 100));
          })
          .catch((error) => {
            console.error(
              `Erro ao ajustar estoque para ProdutoId ${adjustment.ProdutoId}:`,
              error
            );
            errors.push(
              `Erro ao ajustar estoque para ProdutoId ${adjustment.ProdutoId}: ${error.response?.data?.errors?.comando?.join(
                ", "
              ) || "Erro desconhecido."}`
            );
          })
      );
  
      await Promise.all(promises);
  
      // Se houver erros nos ajustes, exibir no modal
      if (errors.length > 0) {
        setValidationError(errors.join("\n"));
        setShowErrorModal(true);
      } else {
        notify({
          type: "success",
          message: "Estoque importado e ajustado com sucesso.",
        });
        setFile(null);
      }
    } catch (error) {
      console.error("Erro ao importar e ajustar estoque:", error);
      notify({ type: "error", message: "Erro ao importar e ajustar estoque." });
    } finally {
      setIsProcessing(false);
    }
  };

  const downloadTemplate = () => {
    const data = [
      { Codigo: "ABC123", Quantidade: 10 },
      { Codigo: "XYZ789", Quantidade: 25 },
      { Codigo: "LMN456", Quantidade: 15 },
      { Codigo: "PQR678", Quantidade: 30 },
      { Codigo: "DEF234", Quantidade: 5 },
    ];

    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Modelo");
    XLSX.writeFile(workbook, "modelo_importacao_estoque.xlsx");
  };

  const downloadErrors = () => {
    const blob = new Blob([validationError], { type: "text/plain;charset=utf-8" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "erros_importacao.txt";
    link.click();
  };

  const handleBack = () => {
    if (onRefresh) {
      onRefresh(); // Notifica o pai para atualizar a grid
    }
    onBack(); // Voltar para a tela anterior
  };

  return (
    <div className="card">
      <h5>Importar Estoque</h5>
      <div className="mb-3">
        <label htmlFor="empresa" className="form-label me-2">
          Selecione a Empresa:
        </label>
        <Dropdown
          id="empresa"
          value={selectedEmpresa}
          options={ListaEmpresas}
          onChange={(e) => onSelectEmpresa(e.value)}
          optionLabel="name"
          placeholder="Selecione a Empresa"
          className="w-full"
        />
      </div>

      <div className="mb-3">
        <label htmlFor="file" className="form-label">
          Arquivo Excel:
        </label>
        <input
          type="file"
          id="file"
          accept=".xlsx"
          onChange={handleFileChange}
          className="form-control"
        />
      </div>

      <div className="mb-3">
        {!isProcessing && (
            <>
                <Button
                    label="Importar Estoque"
                    icon="pi pi-upload"
                    className="me-4"
                    onClick={handleUpload}
                    disabled={!file || !selectedEmpresa}
                />
                <Button
                label="Baixar Modelo"
                icon="pi pi-download"
                className="p-button-secondary"
                onClick={downloadTemplate}
                />
            </>
        )}
      </div>

      {isProcessing && (
        <div className="text-center">
            <ProgressSpinner />
            <ProgressBar value={progress} />
            <p>Importando estoque: {progress}% concluído.</p>
        </div>
        )}

      {validationError && (
        <Dialog
          header="Erros Encontrados"
          visible={showErrorModal}
          style={{ width: "50vw" }}
          onHide={() => setShowErrorModal(false)}
          footer={
            <>
              <Button
                label="Baixar Erros"
                icon="pi pi-download"
                onClick={downloadErrors}
                className="p-button-danger me-3"
              />
              <Button
                label="Fechar"
                icon="pi pi-times"
                onClick={() => setShowErrorModal(false)}
              />
            </>
          }
        >
          <p>
            Foram encontrados erros no arquivo enviado. Por favor, revise as
            mensagens abaixo e corrija as inconsistências no arquivo:
          </p>
          <ul>
            {validationError.split("\n").map((error) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        </Dialog>
      )}
    </div>
  );
};

export default ImportarEstoque;
