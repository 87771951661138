import { useState, useEffect } from "react";
import { useNavigate, createSearchParams } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
// import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";

const ListaFuncionario = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  // const notify = useNotification();
  const navigate = useNavigate();

  const { StatusFuncionario } = MaskEnum;
  const StateDatafilter = {
    global: { value: null, matchMode: "contains" },
    nome: {
      operator: "and",
      constraints: [{ value: null, matchMode: "startsWith" }]
    },
    cpf: {
      operator: "and",
      constraints: [{ value: null, matchMode: "startsWith" }]
    },
    status: {
      operator: "and",
      constraints: [{ value: null, matchMode: "startsWith" }]
    }
  };

  const [filters, setFilters] = useState(StateDatafilter);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  const [Lista, setLista] = useState([]);

  const onGlobalFilterChange = (e) => {
    try {
      const { value } = e.target;
      const thisFilters = { ...filters };

      thisFilters.global.value = value;

      setFilters(thisFilters);
      setGlobalFilterValue(value);
    } catch (error) {
      console.error(error);
    }
  };

  const InitFilters = () => {
    setFilters(StateDatafilter);
    setGlobalFilterValue("");
  };

  const ClearFilter = () => {
    InitFilters();
  };

  useEffect(() => {
    const StateDataPage = async () => {
      try {
        setLoading(true);
        const response = await Requicicao.Get({
          endpoint: "/Funcionarios/All",
          config: auth.GetHeaders()
        });
        if (response.codigoEstadoHttp === 200) {
          setLista(
            response.objetoResposta.map((item) => ({
              ...item,
              status: StatusFuncionario.find((x) => x.value === item.status)
                ?.label
            }))
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    if (auth.isAuthenticated()) {
      StateDataPage();
    }
  }, [auth]);

  const SortFuncionario = (a, b) => {
    // Pega a data de atualização ou data de criação, se a atualização for null
    const dataA = new Date(a.dataAtualizacao || a.dataCriacao);
    const dataB = new Date(b.dataAtualizacao || b.dataCriacao);

    // Ordena de forma decrescente (mais recente primeiro)
    return dataB - dataA;
  };

  return (
    <div className="card p-3">
      <div className="d-flex align-items-center gap-3 mb-3">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Limpar filtro"
          outlined
          onClick={ClearFilter}
        />
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Pesquisa Global"
          />
        </span>
      </div>
      <DataTable
        value={Lista.sort(SortFuncionario)}
        stripedRows
        paginator
        rows={20}
        emptyMessage="Nenhum funcionário encontrado."
        globalFilterFields={["nome", "cpf", "status"]}
        filters={filters}
      >
        <Column field="nome" header="Nome" sortable />
        <Column
          field="cpf"
          header="CPF"
          sortable
          body={(row) => MaskUtil.applyCpfMask(row.cpf)}
        />
        <Column field="status" header="Status" sortable />
        <Column field="empresaNome" header="Empresa" />
        <Column field="empresaCodigoLoja" header="Codigo da Empresa" />
        <Column field="empresaAlocacaoNome" header="Alocado" />
        <Column field="empresaAlocacaoCodigoLoja" header="Codigo do Alocado" />
        <Column
          field="dataCriacao"
          header="Data de Criação"
          sortable
          body={(row) => MaskUtil.applyDataMask(row.dataCriacao)}
        />
        <Column
          header="Ação"
          body={(row) => (
            <div className="d-flex gap-2">
              <Button
                label="Editar"
                icon="pi pi-pencil"
                className="btn btn-tabela btn-outline-primary btn-sm "
                onClick={() =>
                  navigate(`/app/rh/funcionarios/editar/${row.id}`)
                }
              />
              <Button
                label="Empresa"
                icon="pi pi-building"
                className="btn btn-tabela btn-outline-secondary btn-sm"
                onClick={() =>
                  navigate({
                    pathname: "/app/rh/funcionarios/contratados",
                    search: createSearchParams({
                      filial: row.empresaId,
                      matriz: row.matrizId,
                      filtro: row.cpf
                    }).toString()
                  })
                }
              />
              <Button
                label="Alocação"
                icon="pi pi-users"
                className="btn btn-tabela btn-outline-success btn-sm"
                onClick={() =>
                  navigate({
                    pathname: "/app/rh/funcionarios/alocados",
                    search: createSearchParams({
                      filial: row.empresaAlocacaoId,
                      matriz: row.matrizId,
                      filtro: row.cpf
                    }).toString()
                  })
                }
                disabled={row.empresaAlocacaoId == null}
              />
            </div>
          )}
        />
      </DataTable>
    </div>
  );
};
export default ListaFuncionario;
