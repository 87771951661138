// utils/validacaoUtils.js

export const validarCPF = (rawCpf) => {
    const cpf = rawCpf.replace(/[^\d]+/g, "");
  
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
  
    let soma = 0;
    for (let i = 0; i < 9; i += 1) {
      soma += parseInt(cpf.charAt(i), 10) * (10 - i);
    }
  
    let resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.charAt(9), 10)) return false;
  
    soma = 0;
    for (let i = 0; i < 10; i += 1) {
      soma += parseInt(cpf.charAt(i), 10) * (11 - i);
    }
  
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
  
    return resto === parseInt(cpf.charAt(10), 10);
  };
  
  export default validarCPF;
  