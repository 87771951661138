import React from "react";

import PropTypes from "prop-types";

import { FileUpload } from "primereact/fileupload";

const Documentos = ({ HandleFileChange }) => (
  <div className="row g-3">
    <div className="col-sm-12 col-md-6">
      <label htmlFor="foto" className="form-label">
        FOTO*
      </label>
      <FileUpload
        name="foto"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "foto")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="pisEsocial" className="form-label">
        PIS | E-SOCIAL*
      </label>
      <FileUpload
        name="pisEsocial"
        customUpload
        auto={false}
        uploadHandler={null}
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "pisEsocial")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="rgCpf" className="form-label">
        RG ou CPF*
      </label>
      <FileUpload
        name="rgCpf"
        customUpload
        auto={false}
        uploadHandler={null}
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "rgCpf")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="dadosPessoais" className="form-label">
        DADOS PESSOAIS*
      </label>
      <FileUpload
        name="dadosPessoais"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "dadosPessoais")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="endereco" className="form-label">
        ENDEREÇO*
      </label>
      <FileUpload
        name="endereco"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "endereco")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="carteiraTrabalho" className="form-label">
        CARTEIRA DE TRABALHO*
      </label>
      <FileUpload
        name="carteiraTrabalho"
        customUpload
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "carteiraTrabalho")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="dadosBancarios" className="form-label">
        DADOS BANCÁRIOS*
      </label>
      <FileUpload
        name="dadosBancarios"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "dadosBancarios")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="exameAdmissional" className="form-label">
        EXAME ADMISSIONAL*
      </label>
      <FileUpload
        name="exameAdmissional"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "exameAdmissional")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="tituloEleitor" className="form-label">
        TÍTULO DE ELEITOR
      </label>
      <FileUpload
        name="tituloEleitor"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "tituloEleitor")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="reservista" className="form-label">
        RESERVISTA
      </label>
      <FileUpload
        name="reservista"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "reservista")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="cnh" className="form-label">
        CNH
      </label>
      <FileUpload
        name="cnh"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "cnh")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="escolaridade" className="form-label">
        ESCOLARIDADE
      </label>
      <FileUpload
        name="escolaridade"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "escolaridade")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="dependentes" className="form-label">
        DEPENDENTES
      </label>
      <FileUpload
        name="dependentes"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "dependentes")}
      />
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="pensaoAlimenticia" className="form-label">
        PENSÃO ALIMENTÍCIA
      </label>
      <FileUpload
        name="pensaoAlimenticia"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        accept="application/pdf,image/jpeg,image/png"
        onSelect={(e) => HandleFileChange(e, "pensaoAlimenticia")}
      />
    </div>
  </div>
);

Documentos.propTypes = {
  HandleFileChange: PropTypes.func.isRequired
};

export default Documentos;
