/* eslint-disable no-plusplus */

import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

class MaskUtil {

  static valicaDado(value) {
    return value == null
  }

  static applyNumeroBancoMask(value) {
    if (this.valicaDado(value)) return null
    return this.applyGenericMask(value, '###');
  }

  static applyNumeroAgenciaMask(value) {
    if (this.valicaDado(value)) return null
    return this.applyGenericMask(value, '####');
  }

  static applyNumeroContaMask(value) {
    if (this.valicaDado(value)) return null
    return this.applyGenericMask(value.replace(/\D/g, ''), '#######-#');
  }

  static applyDataUSMask(value) {
    try {
      if (this.valicaDado(value)) return null
      return format(new Date(value), "yyyy-MM-dd");
    } catch (error) {
      console.error("Erro ao formatar a data:", error);
      return null;
    }
  }

  static applyDataMask(value) {
    try {
      if (this.valicaDado(value)) return null
      return format(new Date(value), "dd/MM/yyyy");
    } catch (error) {
      console.error("Erro ao formatar a data:", error);
      return null;
    }
  }

  static applyMesAnoMask(value) {
    try {
      if (this.valicaDado(value)) return null
      return format(new Date(value), "MMMM yyyy", { locale: ptBR });
    } catch (error) {
      console.error("Erro ao formatar a data:", error);
      return null;
    }
  }

  static applyDataAndHoraMask(value) {
    try {
      if (this.valicaDado(value)) return null
      return format(new Date(value), "dd/MM/yyyy HH:mm:ss");
    } catch (error) {
      console.error("Erro ao formatar a data e hora:", error);
      return null;
    }
  }

  static applyCnhMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, '###########');
  }

  static applyRgMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, '##.###.###-##');
  }

  static applyCpfMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, '###.###.###-##');
  }

  static applyCnpjMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, '##.###.###/####-##');
  }

  static applyCpfCnpjMask(value) {
    // Remove caracteres não numéricos
    const numericValue = value.replace(/\D/g, '');

    // Aplica a máscara independentemente do número de caracteres
    if (numericValue.length < 12) {
      // Máscara para CPF
      return this.applyCpfMask(numericValue);
    }

    // Máscara para CNPJ
    return this.applyCnpjMask(numericValue)
  }

  static applyCepMask(value) {
    if (this.valicaDado(value)) return null
    const numericValue = value.replace(/\D/g, '');
    return this.applyGenericMask(numericValue, "#####-###")
  }

  static applyTelefoneMask(value) {
    if (!value) return "";
  
    const numericValue = value.replace(/\D/g, "").slice(0, 11); // Limita a 11 dígitos
  
    if (numericValue.length <= 10) {
      // (99) 9999-9999
      return numericValue.replace(/(\d{2})(\d{4})(\d{0,4})/, "($1) $2-$3");
    }
  
    // (99) 99999-9999
    return numericValue.replace(/(\d{2})(\d{5})(\d{0,4})/, "($1) $2-$3");
  }

  static formatarMoeda(valor) {
    const numero = valor.replace(/\D/g, "");
    const formatado = (Number(numero) / 100).toFixed(2);
    return formatado
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  static applyMonetaryMask(value) {
    const numericValue = String(value);

    // Encontra a posição do ponto decimal
    const decimalPosition = numericValue.indexOf('.');

    // Separa a parte inteira e parte decimal
    let integerPart; let decimalPart;
    if (decimalPosition !== -1) {
      integerPart = numericValue.slice(0, decimalPosition);
      decimalPart = numericValue.slice(decimalPosition + 1).padEnd(2, '0');
    } else {
      integerPart = numericValue;
      decimalPart = '00';
    }

    // Aplica a máscara monetária
    const formattedValue = `${this.applyGenericMask(integerPart, '###.###.###')},${decimalPart}`;

    return formattedValue;
  }

  static applyGenericMask(value, mask) {
    let maskedValue = '';
    let i = 0;
    let j = 0;

    while (i < value.length && j < mask.length) {
      if (mask[j] === '#') {
        maskedValue += value[i];
        i++;
      } else {
        maskedValue += mask[j];
      }
      j++;
    }

    return maskedValue;
  }

  static convertDateToIso(date) {
    const [day, month, year] = date.split('/');
    return new Date(`${year}-${month}-${day}`).toISOString();
  }
  
}

export default MaskUtil;
