import React from "react";

import PropTypes from "prop-types";

import { FileUpload } from "primereact/fileupload";
import { InputMask } from "primereact/inputmask";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";

import MaskUtil from "utils/MaskUtil";

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import InputDateSomentePicker from "components/RH/inputs/InputDateSomentePicker";

import ptBR from "date-fns/locale/pt-BR";

registerLocale("pt-BR", ptBR);

const ExameMedico = ({
  dados,
  HandleChange,
  statusAptidao,
  setStatusAptidao,
  HandleExameChange
}) => (
  <div className="row g-3">
    <div className="col-md-6 col-lg-3">
      <label htmlFor="nomeMedico" className="form-label">
        Nome do Médico
      </label>
      <InputText
        id="nomeMedico"
        name="nomeMedico"
        value={dados.nomeMedico}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="crm" className="form-label">
        CRM
      </label>
      <InputMask
        id="crm"
        name="crm"
        value={dados.crm}
        onChange={HandleChange}
        className="form-control"
        mask="99999999-9/BR"
      />
    </div>
    <div className="col-md-6 col-lg-3">
      <label htmlFor="dataAdmissaoExam" className="form-label">
        Data do Exame
      </label>
      <DatePicker
          selected={dados.dataAdmissaoExam}
          onChange={(date) =>
            HandleChange({
              target: {
                name: "dataAdmissaoExam",
                value: date
              }
            })
          }
          dateFormat="dd/MM/yyyy"
          className="form-control w-100"
          customInput={<InputDateSomentePicker />}
          locale="pt-BR"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
        />
    </div>
    <div className="col-md-6 col-lg-3">
      <label className="mb-2">Status de Aptidão Médica</label>
      <div className="d-flex flex-wrap gap-3">
        <div className="d-flex align-items-center gap-1">
          <RadioButton
            inputId="statu1"
            name="statusAptidao"
            value="Apto"
            onChange={(e) => setStatusAptidao(e.value)}
            checked={statusAptidao === "Apto"}
          />
          <label htmlFor="statu1">Apto</label>
        </div>
        <div className="d-flex align-items-center gap-1">
          <RadioButton
            inputId="statu2"
            name="statusAptidao"
            value="Não Apto"
            onChange={(e) => setStatusAptidao(e.value)}
            checked={statusAptidao === "Não Apto"}
          />
          <label htmlFor="statu2">Não Apto</label>
        </div>
      </div>
    </div>
    <div className="col-sm-12 col-md-6">
      <label htmlFor="foto" className="form-label">
        Exame
      </label>
      <FileUpload
        name="foto"
        chooseLabel="Escolher"
        uploadLabel="Enviar"
        cancelLabel="Cancelar"
        customUpload
        auto={false}
        uploadHandler={null}
        multiple={false}
        onSelect={HandleExameChange}
      />
    </div>
  </div>
);

ExameMedico.propTypes = {
  dados: PropTypes.shape({
    nomeMedico: PropTypes.string.isRequired,
    crm: PropTypes.string.isRequired
  }).isRequired,
  HandleChange: PropTypes.func.isRequired,
  setStatusAptidao: PropTypes.func.isRequired,
  HandleExameChange: PropTypes.func.isRequired,
  statusAptidao: PropTypes.string.isRequired
};

export default ExameMedico;
