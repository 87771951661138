// src/components/RH/inputs/InputDateSomentePicker.jsx

import React, { forwardRef } from "react";

const InputDateSomentePicker = forwardRef(({ value, onClick }, ref) => (
  <input
    className="form-control"
    onClick={onClick}
    value={value}
    ref={ref}
    onKeyDown={(e) => e.preventDefault()} // bloqueia digitação
    readOnly // impede edição manual
  />
));

export default InputDateSomentePicker;
