import React from "react";

import PropTypes from "prop-types";

import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import InputDateSomentePicker from "components/RH/inputs/InputDateSomentePicker";

import ptBR from "date-fns/locale/pt-BR";

import MaskUtil from "utils/MaskUtil";
import MaskEnum from "utils/MaskEnum";


registerLocale("pt-BR", ptBR);

const { TipoEscala } = MaskEnum;

const DadosAdmissionais = ({
  dados,
  setDados,
  funcoes,
  HandleDropdownChange,
  HandleChange,
  departamentos,
  escolaridades,
  regimes,
  jornadas,
  pcds,
  BoxBtns
}) => (
  <div className="row g-3">
    <div className="col-md-4">
      <label htmlFor="funcaoId" className="form-label">
        Função
      </label>
      <Dropdown
        id="funcaoId"
        value={dados.funcaoId}
        onChange={(e) => HandleDropdownChange(e, "funcaoId")}
        options={funcoes}
        placeholder="Selecione uma Função"
        className="w-100"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="cargo" className="form-label">
        Cargo
      </label>
      <InputText
        id="cargo"
        name="cargo"
        value={dados.cargo}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="departamentoId" className="form-label">
        Departamento
      </label>
      <Dropdown
        id="departamentoId"
        value={dados.departamentoId}
        onChange={(e) => HandleDropdownChange(e, "departamentoId")}
        options={departamentos}
        placeholder="Selecione um Departamento"
        className="w-100"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="escolaridadeId" className="form-label">
        Escolaridade
      </label>
      <Dropdown
        id="escolaridadeId"
        value={dados.escolaridadeId}
        onChange={(e) => HandleDropdownChange(e, "escolaridadeId")}
        options={escolaridades}
        placeholder="Selecione uma Escolaridade"
        className="w-100"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="dataAdmissao" className="form-label">
        Data de Admissão
      </label>
      <DatePicker
        id="dataAdmissao"
        selected={dados.dataAdmissao}
        onChange={(date) =>
          setDados((prev) => ({ ...prev, dataAdmissao: date }))
        }
        dateFormat="dd/MM/yyyy"
        className="form-control w-100"
        customInput={<InputDateSomentePicker />}
        locale="pt-BR"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="banco" className="form-label">
        Banco
      </label>
      <InputText
        id="banco"
        name="banco"
        value={dados.banco}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="agencia" className="form-label">
        Agência
      </label>
      <InputText
        id="agencia"
        name="agencia"
        value={dados.agencia}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="contaCorrente" className="form-label">
        Conta Corrente
      </label>
      <InputText
        id="contaCorrente"
        name="contaCorrente"
        value={dados.contaCorrente}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="dataAdmissaoExam" className="form-label">
        Data do Exame Admissional
      </label>
      <DatePicker
        id="dataAdmissaoExam"
        selected={dados.dataAdmissaoExam}
        onChange={(date) =>
          setDados((prev) => ({ ...prev, dataAdmissaoExam: date }))
        }
        dateFormat="dd/MM/yyyy"
        className="form-control w-100"
        customInput={<InputDateSomentePicker />}
        locale="pt-BR"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="tamanhoUniforme" className="form-label">
        Tamanho do Uniforme
      </label>
      <Dropdown
        id="tamanhoUniforme"
        name="tamanhoUniforme"
        value={dados.tamanhoUniforme}
        onChange={(e) => setDados({ ...dados, tamanhoUniforme: e.value })}
        options={["PP", "P", "M", "G", "GG"]}
        placeholder="Selecione o tamanho"
        className="w-100"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="salario" className="form-label">
        Salário
      </label>
      <InputText
        id="salario"
        name="salario"
        value={dados.salario}
        onChange={(e) =>
          setDados({
            ...dados,
            salario: MaskUtil.formatarMoeda(e.target.value)
          })
        }
        onKeyPress={(e) => {
          if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
          }
        }}
        className="form-control"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="horarioTrabalho" className="form-label">
        Horário de Trabalho
      </label>
      <InputText
        id="horarioTrabalho"
        name="horarioTrabalho"
        value={dados.horarioTrabalho}
        onChange={HandleChange}
        className="form-control"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="regimeContratacaoId" className="form-label">
        Regime de Contratação
      </label>
      <Dropdown
        id="regimeContratacaoId"
        value={dados.regimeContratacaoId}
        onChange={(e) => HandleDropdownChange(e, "regimeContratacaoId")}
        options={regimes}
        placeholder="Selecione o Regime de Contratação"
        className="w-100"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="jornadaTrabalhoId" className="form-label">
        Jornada de Trabalho
      </label>
      <Dropdown
        id="jornadaTrabalhoId"
        value={dados.jornadaTrabalhoId}
        onChange={(e) => HandleDropdownChange(e, "jornadaTrabalhoId")}
        options={jornadas}
        placeholder="Selecione a Jornada de Trabalho"
        className="w-100"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="pcdId" className="form-label">
        PCD
      </label>
      <Dropdown
        id="pcdId"
        value={dados.pcdId}
        onChange={(e) => HandleDropdownChange(e, "pcdId")}
        options={pcds}
        placeholder="Selecione PCD"
        className="w-100"
      />
    </div>
    <div className="col-md-4">
      <label htmlFor="tipoEscala" className="form-label">
        Tipo Escala
      </label>
      <Dropdown
        id="tipoEscala"
        value={dados.tipoEscala}
        onChange={(e) => HandleDropdownChange(e, "tipoEscala")}
        options={TipoEscala}
        className="w-100"
      />
    </div>
    <div className="col-12">
      <BoxBtns />
    </div>
  </div>
);

DadosAdmissionais.propTypes = {
  dados: PropTypes.shape({
    funcaoId: PropTypes.string,
    cargo: PropTypes.string,
    departamentoId: PropTypes.string,
    escolaridadeId: PropTypes.string,
    dataAdmissao: PropTypes.instanceOf(Date),
    banco: PropTypes.string,
    agencia: PropTypes.string,
    contaCorrente: PropTypes.string,
    dataAdmissaoExam: PropTypes.instanceOf(Date),
    tamanhoUniforme: PropTypes.oneOf(["PP", "P", "M", "G", "GG"]),
    salario: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    horarioTrabalho: PropTypes.string,
    regimeContratacaoId: PropTypes.string,
    jornadaTrabalhoId: PropTypes.string,
    pcdId: PropTypes.string
  }).isRequired,
  setDados: PropTypes.func.isRequired,
  funcoes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  HandleDropdownChange: PropTypes.func.isRequired,
  HandleChange: PropTypes.func.isRequired,
  departamentos: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  escolaridades: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  regimes: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  jornadas: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  pcds: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.string })
  ).isRequired,
  BoxBtns: PropTypes.func.isRequired
};

export default DadosAdmissionais;
