import { useState, useEffect } from "react";

import { format } from "date-fns";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";

import { useAuth } from "context/AuthContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const CustomizacaoPDVs = () => {
  const auth = useAuth();
  const notificacao = useNotification();
  const requisicao = new Api();

  const [customizacoes, setCustomizacoes] = useState([]);
  const [customizacaoSelecionada, setSelectedCustomizacao] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const [isNewCustomizacao, setIsNewCustomizacao] = useState(false);

  const carregarCustomizacoes = async () => {
    try {
      const resposta = await requisicao.Get({
        endpoint: "/Customizacao/ObterTodas",
        config: auth.GetHeaders()
      });

      setCustomizacoes(resposta.objetoResposta);
    } catch (error) {
      notificacao({
        type: "error",
        message: "Erro ao carregar as customizações."
      });
      console.error(error);
    }
  };

  const salvarCustomizacao = async (novaCustomizacao) => {
    try {
      await requisicao.Post({
        endpoint: "/Customizacao/Criar",
        data: novaCustomizacao,
        config: auth.GetHeaders()
      });

      notificacao({
        type: "success",
        message: "Customização salva com sucesso!"
      });
      carregarCustomizacoes();
      setIsNewCustomizacao(false);
    } catch (error) {
      notificacao({ type: "error", message: "Erro ao salvar a customização." });
      console.error(error);
    }
  };

  const handleViewDetails = (customizacao) => {
    setSelectedCustomizacao(customizacao);
    setShowModal(true);
  };

  useEffect(() => {
    if (auth.isAuthenticated()) {
      carregarCustomizacoes();
    }
  }, [auth]);

  const renderModalContent = () => {
    if (!customizacaoSelecionada) return null;

    return (
      <div>
        <h5>Detalhes da Customização</h5>
        <p>
          <strong>Código da Loja:</strong> {customizacaoSelecionada.codigoLoja}
        </p>
        <p>
          <strong>Permitir Venda Abaixo do Valor de Custo:</strong>{" "}
          {customizacaoSelecionada.vendaPermitirAbaixoValorCusto
            ? "Sim"
            : "Não"}
        </p>
        <p>
          <strong>Porcentagem Máxima de Acréscimo:</strong>{" "}
          {customizacaoSelecionada.vendaPorcentagemMaximaAcrescimo ||
            "Não definida"}
        </p>
        <p>
          <strong>Porcentagem Máxima de Desconto:</strong>{" "}
          {customizacaoSelecionada.vendaPorcentagemMaximaDesconto ||
            "Não definida"}
        </p>
        <p>
          <strong>Data de Criação:</strong>{" "}
          {format(
            new Date(customizacaoSelecionada.dataCriacao),
            "dd/MM/yyyy HH:mm:ss"
          )}
        </p>
        {customizacaoSelecionada.dataAtualizacao && (
          <p>
            <strong>Última Atualização:</strong>{" "}
            {format(
              new Date(customizacaoSelecionada.dataAtualizacao),
              "dd/MM/yyyy HH:mm:ss"
            )}
          </p>
        )}
      </div>
    );
  };

  const actionBodyTemplate = (rowData) => (
    <div className="d-flex">
      <Button
        icon="pi pi-eye"
        label="Visualizar"
        onClick={() => handleViewDetails(rowData)}
        className="mr-2"
      />
    </div>
  );

  return (
    <div className="card p-3">
      <DataTable
        value={customizacoes}
        paginator
        rows={10}
        sortField="dataCriacao"
        sortOrder={-1}
      >
        <Column field="codigoLoja" header="Código Loja" />
        <Column
          field="vendaPermitirAbaixoValorCusto"
          header="Venda Abaixo do Custo"
          body={(rowData) =>
            rowData.vendaPermitirAbaixoValorCusto ? "Sim" : "Não"
          }
        />
        <Column
          field="vendaPorcentagemMaximaAcrescimo"
          header="% Máx. Acréscimo"
        />
        <Column
          field="vendaPorcentagemMaximaDesconto"
          header="% Máx. Desconto"
        />
        <Column body={actionBodyTemplate} header="Ações" />
      </DataTable>
      <Dialog
        header="Detalhes da Customização"
        visible={showModal}
        style={{ width: "50vw" }}
        modal
        onHide={() => setShowModal(false)}
      >
        {renderModalContent()}
      </Dialog>
    </div>
  );
};

export default CustomizacaoPDVs;
