const ListaReplace = [
  { name: "app", replace: "Portal" },
  { name: "empresa", replace: "Balcão" },
  { name: "new", replace: "Criar" },
  { name: "editar", replace: "Editar" },
  { name: "produto", replace: "Produto" },
  { name: "grupo", replace: "Grupo de Produto" },
  { name: "usuario", replace: "Usuário" },
  { name: "pontoVenda", replace: "Loja" },
  { name: "conta", replace: "Conta" },
  { name: "estoque", replace: "Estoque" },
  { name: "relatorio", replace: "Visão" },
  { name: "filial", replace: "Filial" },
  { name: "statusfiliais", replace: "Status Filiais" },
  { name: "vendedor", replace: "Vendedor" },
  { name: "inventario", replace: "Inventário" },
  { name: "implantacao", replace: "Implantação" },
  { name: "operador", replace: "Operador" },
  { name: "customizacaoPdvs", replace: "Customização PDVs" },
  { name: "painelIndicadores", replace: "Painel Indicadores" }
];

export default ListaReplace;
