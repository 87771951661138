/* eslint-disable eqeqeq */
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from "primereact/fileupload";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskEnum from "utils/MaskEnum";
import MaskUtil from "utils/MaskUtil";
import useQuery from "utils/useQuery";

const UpDocumentoEmpresa = () => {
  const notify = useNotification();
  const query = useQuery();
  const navigate = useNavigate();
  const auth = useAuth();
  const { setLoading } = useLoading();
  const Requicicao = new Api();

  const [Dados, setDados] = useState({
    empresaId: null,
    nome: "",
    dataRelacao: null,
    descricao: "",
    tipoDocumento: null,
    tipoMensal: null,
    tipoAnual: null
  });

  const [arquivo, setArquivo] = useState(null);

  const HandleFileSelect = (e) => {
    setArquivo(e.files[0]); // Captura o primeiro arquivo selecionado
  };

  const HandleInputChange = (e) => {
    const { name, value } = e.target;
    setDados((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const HandleDateChange = (e) => {
    const selectedDate = e.value;
    setDados((prevState) => ({
      ...prevState,
      dataRelacao: MaskUtil.applyDataUSMask(selectedDate)
    }));
  };

  const HasdleDropdownChange = (e, campo) => {
    setDados((prevState) => ({ ...prevState, [campo]: e.value }));
  };

  const ValidaCampos = () => {
    const mensagensErro = [];

    if (Dados.funcionarioId === null) {
      mensagensErro.push("Funcionário não identificado");
    }
    if (Dados.nomeArquivo === "") {
      mensagensErro.push("Informe o nome do documento");
    }
    if (Dados.descricao === "") {
      mensagensErro.push("Informe a descrição do documento");
    }
    if (Dados.tipoArquivo === null) {
      mensagensErro.push("Informe o tipo do documento");
    }

    if (mensagensErro.length > 0) {
      mensagensErro.forEach((msg) =>
        notify({
          type: "aviso",
          message: msg.toString().replace(",", ", ")
        })
      );
      return false;
    }

    return true;
  };

  const CleanData = (data) =>
    Object.fromEntries(
      Object.entries(data).filter(([_, value]) => value != null)
    );

  const Submit = async (params) => {
    params.preventDefault();
    try {
      setLoading(true);

      if (ValidaCampos()) {
        const formData = new FormData();

        const dataToForm = CleanData(Dados);
        // Adiciona os dados do funcionário ao FormData
        for (const key in dataToForm) {
          if (Object.prototype.hasOwnProperty.call(Dados, key)) {
            formData.append(key, Dados[key]);
          }
        }

        // Adiciona o arquivo ao FormData
        if (arquivo) {
          formData.append("arquivoFuncionario", arquivo);
        } else {
          notify({
            type: "aviso",
            message: "Por favor, selecione um arquivo para fazer upload."
          });
          return false;
        }

        await Requicicao.Post({
          endpoint: `/Empresa/DocumentoEmpresa`,
          data: formData,
          config: auth.GetHeaders("multipart/form-data")
        });

        notify({
          type: "sucesso",
          message: "Documento Salvo"
        });
        navigate(-1);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
      notify({
        type: "erro",
        message: "Erro ao salva documento."
      });
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const id = query.get("id");
    if (id != null) {
      setDados((e) => ({
        ...e,
        ...{ empresaId: id, tipoDocumento: query.get("tipo") }
      }));
    }
  }, [query]);

  const TipoDocumento = useCallback(() => {
    const { TipoMensal, TipoAnual } = MaskEnum;
    if (Dados.tipoDocumento != null) {
      if (Dados.tipoDocumento == 2) {
        return (
          <div className="col-md-4">
            <label htmlFor="tipoMensal" className="form-label">
              Tipo de decumento
            </label>
            <Dropdown
              id="tipoMensal"
              className="w-100"
              value={Dados.tipoMensal}
              onChange={(e) => HasdleDropdownChange(e, "tipoMensal")}
              options={TipoMensal}
            />
          </div>
        );
      }
      if (Dados.tipoDocumento == 1) {
        return (
          <div className="col-md-4">
            <label htmlFor="tipoAnual" className="form-label">
              Tipo de decumento
            </label>
            <Dropdown
              id="tipoAnual"
              className="w-100"
              value={Dados.tipoAnual}
              onChange={(e) => HasdleDropdownChange(e, "tipoAnual")}
              options={TipoAnual}
            />
          </div>
        );
      }
      return null;
    }
    return null;
  }, [Dados]);

  return (
    <div className="card p-3">
      <form className="row g-3" onSubmit={Submit}>
        <div className="col-md-4">
          <label htmlFor="nome" className="form-label">
            Nome do documento
          </label>
          <InputText
            id="nome"
            name="nome"
            className="w-100"
            value={Dados.nome}
            onChange={HandleInputChange}
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="descricao" className="form-label">
            Descrição
          </label>
          <InputText
            id="descricao"
            name="descricao"
            className="w-100"
            value={Dados.descricao}
            onChange={HandleInputChange}
          />
        </div>

        <div className="col-md-4">
          <label htmlFor="dataRelacao" className="form-label">
            {Dados.tipoDocumento == 2 ? "Mês " : "Ano "}referência documento
          </label>
          <Calendar
            id="dataRelacao"
            view={Dados.tipoDocumento == 2 ? "month" : "year"}
            dateFormat={Dados.tipoDocumento == 2 ? "mm/yy" : "yy"}
            onChange={HandleDateChange}
            className="w-100"
            value={Dados.dataRelacao ? new Date(Dados.dataRelacao) : null}
          />
        </div>

        <TipoDocumento />

        <div className="col-md-8">
          <FileUpload
            accept="application/pdf"
            chooseLabel="Escolher"
            uploadLabel="Enviar"
            cancelLabel="Cancelar"
            emptyTemplate={
              <p className="m-0">
                Arraste e solte os arquivos aqui para fazer upload.
              </p>
            }
            customUpload
            onSelect={HandleFileSelect}
          />
        </div>

        <div className="col-md-12">
          <div className="d-flex flex-row-reverse gap-2">
            <Button type="submit" label="Enviar" />
            <Button
              type="button"
              className="btn btn-primary"
              label="Voltar"
              onClick={() => navigate(-1)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpDocumentoEmpresa;
